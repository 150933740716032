.message-container {
  width: 530px;
  margin: 20px auto;
}

@media (max-width: 767px) {
  .message-container {
    width: 337px;
  }
}

.message-body {
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  background-color: #fff;
}
.message-header,
.message-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.message-footer {
  margin-top: 20px;
}
