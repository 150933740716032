.admin-modal {
  box-shadow: 0px 4px 16px 0px #00000030;
  margin: 15px;
  max-width: 780px;
  background-color: #fff;
  border-radius: 16px;
  padding: 20px;
}

.admin-modal .top-admin {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.admin-modal .top-admin img {
  border: 10px solid #e18e92;
  border-radius: 100%;
  width: 130px;
  height: 130px;
  display: inline-block;
}

.admin-modal .top-admin h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 2.13px;
  margin: 20px 0;
}

.admin-modal .top-admin p {
  margin: 4px 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.admin-modal .form-bottom .form-group {
  margin: 30px 0;
}

.admin-modal .form-bottom .form-group label {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  margin-bottom: 8px;
}

.admin-modal .form-bottom .form-group input {
  width: 100%;
  background: #f5f5f5;
  border-radius: 4px;
  border: none;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  font-family: Roboto;
}

.admin-modal .form-bottom .form-group input::placeholder {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
}

.admin-modal .form-bottom .form-group input:focus-visible {
  outline: none;
  box-shadow: navajowhite;
}

.admin-modal .form-bottom .form-group.btn-listing {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.admin-modal .form-bottom .btn-listing .btn-border {
  border: 1px solid #363636;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  text-align: center;
  min-width: 160px;
  border-radius: 9px;
  margin-right: 12px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.5s ease;
}

.admin-modal .form-bottom .btn-listing .btn-fill {
  border: 1px solid #990033;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  text-align: center;
  min-width: 160px;
  border-radius: 9px;
  background: #990033;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s ease;
}

.admin-modal .form-bottom .btn-listing .btn-border:hover {
  background: #990033;
  color: #fff;
  border-color: #990033;
}

.admin-modal .form-bottom .btn-listing .btn-fill:hover {
  border-color: #363636;
  color: #363636;
  background: transparent;
}

.admin-modal .form-bottom .form-group textarea::placeholder {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
}

.admin-modal .form-bottom .form-group textarea {
  width: 100%;
  background: #f5f5f5;
  border-radius: 4px;
  border: none;
  padding: 19px 27px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  font-family: Roboto;
}

.form-group.btn-listing {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.btn-listing .btn-border {
  border: 1px solid #363636;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  text-align: center;
  min-width: 160px;
  border-radius: 9px;
  margin-right: 12px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.5s ease;
}

.btn-listing .btn-fill {
  border: 1px solid #990033;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  text-align: center;
  min-width: 160px;
  border-radius: 9px;
  background: #990033;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s ease;
}

.btn-listing .btn-border:hover {
  background: #990033;
  color: #fff;
  border-color: #990033;
}

.btn-listing .btn-fill:hover {
  border-color: #363636;
  color: #363636;
  background: transparent;
}

@media only screen and (max-width: 1200px) {
  .student-work-section {
    padding: 35px 0;
  }

  .student-work-section .inner-student-work {
    padding: 35px 0 0;
  }

  .student-work-section h2 {
    padding: 0 15px;
  }

  .student-work-section .top-tabs {
    padding: 45px 15px;
  }

  .student-work-section .tabs-content {
    padding: 45px 15px;
  }

  .student-work-section .tabs-content .student-details-box h4 {
    font-size: 20px;
  }

  /***************table*************/
}

@media only screen and (max-width: 993px) {
  .student-work-section h2 {
    font-size: 24px;
    line-height: 34px;
  }

  .student-work-section .top-tabs {
    padding: 25px 15px;
  }

  .student-work-section .top-tabs ul li {
    margin: 0 2px;
  }

  .switch .text-box {
    font-size: 16px;
  }

  .switch .slider.round {
    height: 20px;
    top: 19px;
    width: 44px;
  }

  .switch .slider:before {
    width: 16px;
    height: 16px;
    bottom: 2px;
  }

  .student-work-section .tabs-content h3 {
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 40px;
  }

  .student-work-section .tabs-content {
    padding: 30px 15px;
  }

  .student-work-section .tabs-content .student-details-box {
    width: 100%;
  }

  .student-work-section
    .tabs-content
    .student-details-box
    .com-student-details {
    padding: 15px 0 !important;
  }

  .student-work-section .tabs-content .center-box.student-details-box {
    order: 3;
  }

  .student-work-section
    .tabs-content
    .text-img-box
    .left-box.student-details-box
    .student-position-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .student-work-section
    .tabs-content
    .text-img-box
    .left-box.student-details-box {
    margin: 0;
    order: 2;
  }

  .student-work-section
    .tabs-content
    .text-img-box
    .left-box.student-details-box
    .com-student-details {
    flex-direction: row-reverse;
    text-align: left;
  }

  .student-work-section
    .tabs-content
    .student-details-box
    .com-student-details
    .counter-box {
    margin-left: 0;
    margin-right: 20px;
  }

  .student-work-section .tabs-content .right-box.student-details-box {
    order: 1;
  }

  /******************table ******************/

  .sponsorship-requests-section .table-box .scroll-table-box {
    overflow-x: auto;
  }

  .sponsorship-requests-section .table-box table {
    min-width: 1024px;
  }

  .sponsorship-requests-section h2 {
    font-size: 24px;
    line-height: 31.16px;
  }
}

@media only screen and (max-width: 767px) {
  .student-work-section .top-tabs ul li {
    width: 100%;
    margin: 0 0 10px;
  }

  .student-work-section .top-tabs ul li label {
    max-width: 262px;
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .student-work-section .tabs-content h3 {
    margin-bottom: 20px;
  }

  .student-work-section
    .tabs-content
    .student-details-box
    .com-student-details
    .counter-box
    span {
    width: 26px;
    height: 26px;
    font-size: 16px;
    line-height: 19px;
  }

  .student-work-section
    .tabs-content
    .student-details-box
    .com-student-details
    .counter-box {
    width: 35px;
  }

  .student-work-section
    .tabs-content
    .right-box.student-details-box
    .counter-box {
    margin-right: 10px;
  }

  .student-work-section
    .tabs-content
    .student-details-box
    .com-student-details
    .text-box {
    width: calc(100% - 35px);
  }

  .student-work-section
    .tabs-content
    .student-details-box
    .com-student-details
    .counter-box {
    margin-right: 10px;
  }

  .student-work-section .tabs-content .student-details-box {
    margin: 0;
  }

  .student-work-section .tabs-content .student-details-box h4 {
    font-size: 16px;
    line-height: 21px;
  }

  .student-work-section .tabs-content .student-details-box p {
    font-size: 15px;
  }

  .student-work-section
    .tabs-content
    .student-details-box
    .com-student-details {
    align-items: flex-start;
  }

  /*******admin modal*************/

  .admin-modal {
    padding: 20px;
  }

  .admin-modal .top-admin img {
    width: 100px;
    height: 100px;
    border: 5px solid #e18e92;
  }

  .admin-modal .top-admin h2 {
    margin: 5px 0;
  }

  .admin-modal .form-bottom .form-group input {
    padding: 15px;
  }

  .admin-modal .form-bottom .btn-listing .btn-border {
    min-width: 120px;
    padding: 14px;
    font-size: 16px;
  }

  .admin-modal .form-bottom .btn-listing .btn-fill {
    min-width: 120px;
    padding: 14px;
    font-size: 16px;
  }

  .admin-modal .form-bottom .form-group.btn-listing {
    justify-content: center;
  }

  .admin-modal .form-bottom .form-group label {
    font-size: 16px;
  }

  .admin-modal .top-admin p {
    font-size: 16px;
    line-height: 22px;
  }

  .admin-modal .form-bottom .form-group textarea {
    padding: 15px;
  }

  /************************/
}

@media only screen and (max-width: 767px) {
  .admin-modal2 {
    padding: 20px;
  }

  .admin-modal2 .top-admin img {
    width: 100px;
    height: 100px;
    border: 5px solid #e18e92;
  }

  .admin-modal2 .top-admin h2 {
    margin: 5px 0;
  }

  .admin-modal2 .form-bottom .form-group input {
    padding: 15px;
  }

  .admin-modal2 .form-bottom .btn-listing .btn-border {
    min-width: 120px;
    padding: 14px;
    font-size: 16px;
  }

  .admin-modal2 .form-bottom .btn-listing .btn-fill {
    min-width: 120px;
    padding: 14px;
    font-size: 16px;
  }

  .admin-modal2 .form-bottom .form-group.btn-listing {
    justify-content: center;
  }

  .admin-modal2 .form-bottom .form-group label {
    font-size: 16px;
  }

  .admin-modal2 .top-admin p {
    font-size: 16px;
    line-height: 22px;
  }

  .admin-modal2 .form-bottom .form-group textarea {
    padding: 15px;
  }

  /************************/
}

/* .admin-modal2 {
    box-shadow: 0px 4px 16px 0px #00000030;
    margin: 15px;
    max-width: 880px;
    background-color: #fff;
    border-radius: 16px;
    padding: 40px;
} */

.admin-modal2 .top-admin {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}

.admin-modal2 .top-admin img {
  border: 10px solid #e18e92;
  border-radius: 100%;
  width: 130px;
  height: 130px;
  display: inline-block;
}

.admin-modal2 .top-admin h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 2.13px;
  margin: 20px 0;
}

.admin-modal2 .top-admin p {
  margin: 4px 0;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.admin-modal2 .form-bottom .form-group {
  margin: 30px 0;
}

.admin-modal2 .form-bottom .form-group label {
  display: block;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  margin-bottom: 8px;
}

.admin-modal2 .form-bottom .form-group input {
  width: 100%;
  background: #f5f5f5;
  border-radius: 4px;
  border: none;
  padding: 15px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  font-family: Roboto;
}

.admin-modal2 .form-bottom .form-group input::placeholder {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
}

.admin-modal2 .form-bottom .form-group input:focus-visible {
  outline: none;
  box-shadow: navajowhite;
}

.admin-modal2 .form-bottom .form-group.btn-listing {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.admin-modal2 .form-bottom .btn-listing .btn-border {
  border: 1px solid #363636;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  text-align: center;
  min-width: 160px;
  border-radius: 9px;
  margin-right: 12px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.5s ease;
}

.admin-modal2 .form-bottom .btn-listing .btn-fill {
  border: 1px solid #990033;
  padding: 20px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  text-align: center;
  min-width: 160px;
  border-radius: 9px;
  background: #990033;
  color: #fff;
  cursor: pointer;
  transition: all 0.5s ease;
}

.admin-modal2 .form-bottom .btn-listing .btn-border:hover {
  background: #990033;
  color: #fff;
  border-color: #990033;
}

.admin-modal2 .form-bottom .btn-listing .btn-fill:hover {
  border-color: #363636;
  color: #363636;
  background: transparent;
}

.admin-modal2 .form-bottom .form-group textarea::placeholder {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
}

.admin-modal2 .form-bottom .form-group textarea {
  width: 100%;
  background: #f5f5f5;
  border-radius: 4px;
  border: none;
  padding: 19px 27px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  font-family: Roboto;
}

.checkbox-box {
  /* display: inline-block; */
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 100%;
  margin-left: 35px !important;
  top: 0;
}

.checkbox-box input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-box .checkmark {
  position: absolute;
  top: 0;
  left: -35px;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #bbb9b9;
}

.checkbox-box input:checked ~ .checkmark {
  background-color: #19857b;
  border-color: #19857b;
}

.checkbox-box .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-box input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-box .checkmark:after {
  left: 4px;
  top: -1px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.preferences-modal .top-admin {
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.preferences-modal .top-admin .admin-img img {
  border: none;
  width: 100px;
  height: auto;
  margin-right: 15px;
}
.from-two-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0px -15px;
}
.preferences-modal .from-two-group .form-group {
  width: 50%;
  padding: 0px 15px;
  margin-bottom: 0;
}
.preferences-modal .form-group textarea {
  resize: none;
  outline: none;
}
.preferences-modal hr {
  border-top: 1px solid #f1f1f1;
}
.preferences-modal .form-group.mb-0 {
  margin-bottom: 0px;
}
.preferences-modal .form-group.input-group {
  position: relative;
}
.preferences-modal .form-group.input-group input {
  position: absolute;
  left: 0px;
  top: 3px;
  width: auto;
  margin: 0;
}
.preferences-modal .form-group.input-group label {
  margin: 0;
}
.form-group.input-group.mt-10 {
  margin-top: 10px;
  padding: 0;
}
.from-two-group.m0 {
  margin: 0px;
}

.admin-modal2.preferences-modal .form-group.btn-listing.pay-receipt {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.admin-modal2.preferences-modal .form-group.btn-listing.pay-receipt button {
  margin: 0;
}

.categories .scroll-table-box {
  position: relative;
  overflow-x: auto;
}

.admin-modal2 .categories table tr td {
  border-bottom: 1px solid #f1f1f1;
  padding: 0px;
}
.admin-modal2 .categories table tr td:nth-child(4),
.admin-modal2 .categories table tr td:nth-child(5) {
  width: 30%;
}
.categories .scroll-table-box input {
  padding: 0;
  margin: 0;
}
.categories .images img {
  width: 50px;
}

.admin-modal2 .categories .form-group {
  margin: 10px 0px;
  padding: 0px 15px;
}
.categories .table-box table tr:nth-child(2n + 2) td {
  background: transparent;
}
.text-right {
  text-align: right;
}

.preferences-modal .top-admin .admin-img {
  margin-right: 20px;
}

@media (max-width: 991px) {
  .submit-box {
    padding: 15px 15px 30px 15px;
  }
  .bg-purple {
    border: 5px solid #f0edff;
  }
  .quest {
    width: 90px;
    height: 90px;
  }
  .content-box h2 {
    font-size: 22px;
    margin: 15px 0px 15px 0px;
  }
  .value {
    font-size: 70px;
  }
  .submit-pledge .content-box .number1 p {
    font-size: 17px;
  }

  .mid-bg {
    background: url(../assets/banner-img.jpg) center center no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
    margin-top: 40px;
    padding: 50px 0px 50px 0px;
  }

  .mid-bg {
    padding: 50px 0;
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .main-box {
    padding: 50px 30px;
  }
  .submit-pledge ul li {
    width: 100%;
    padding: 0px 15px;
    margin-bottom: 30px;
  }
  .get-start {
    margin-top: 20px;
  }
  .request-value .content-box h2 {
    min-height: auto;
    margin-bottom: 15px;
  }
  .submit-pledge .content-box .number1 p {
    min-height: auto;
  }
  .preferences-modal .top-admin .admin-img img {
    width: 75px;
    margin-right: 7px;
  }
  .admin-modal2 .categories .form-group {
    margin: 10px 0px;
    padding: 0px 7px;
  }
  .categories .scroll-table-box table {
    width: 470px;
  }
}

@media (max-width: 480px) {
}
