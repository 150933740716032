.mid-bg {
  background-color: #fff9f7;
  width: 100%;
  height: 100%;
  background-size: cover;
  margin-top: -40px;
  padding: 100px 0px 130px 0px;
}

.main-box {
  background: #ffffff;
  border-radius: 16px;
  margin-top: -13px;
  padding: 70px 60px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.19);
}

.submit-box {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.19);
  border-radius: 16px;
  padding: 30px 25px 86px 25px;
  height: 100%;
  position: relative;
}
.submit-box .apply-now {
  position: absolute;
  bottom: 35px;
  left: 0;
  right: 0;
  width: calc(100% - 50px);
  margin: 0 auto;
}
.title-heading {
  text-align: center;
  margin-bottom: 70px;
}
.title-style {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
}
.title-heading p {
  font-size: 18px;
  line-height: 30px;
  padding-top: 10px;
}
.submit-pledge ul {
  margin: 0px -15px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  list-style-type: none;
  text-align: center;
  padding: 0;
}
.submit-pledge ul li {
  width: 33.33%;
  padding: 0px 15px;
}

.content-box h2 {
  margin: 15px 0px 30px 0px;
  font-size: 30px;
  font-weight: 500;
  line-height: 35.16px;
}

.request-value .content-box h2 {
  min-height: 60px;
  margin-bottom: 15px;
}
.request-value .content-box .number1 p {
  min-height: 50px;
}

.content-box p {
  font-size: 18px;
  line-height: 24px;
}
.submit-pledge .content-box p {
  margin-bottom: 25px;
}
.apply-now {
  background: #af3636;
  color: #fff;
  font-size: 16px;
  width: 100%;
  display: flex;
  height: 46px;
  border-radius: 35px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  text-decoration: none;
  font-weight: 500;
}

.apply-now:hover {
  background: #9f2a50;
  color: #fff;
}
.get-start a span svg {
  margin-left: 10px;
}

.get-start {
  display: block;
  text-align: center;
  margin-top: 50px;
}

.get-start-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
  border-radius: 35px;
  border: 1px solid #000;
  max-width: 250px;
  margin: 0 auto;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  color: #000;
  text-decoration: none;
}

.get-start-btn:hover {
  background: #990033;
  color: #fff;
  border: 1px solid #990033;
}

.get-start-btn:hover span svg path {
  fill: #fff;
}

.get-start a span svg {
  margin-left: 10px;
}

.quest {
  width: 130px;
  height: 130px;
  margin: 0px auto 30px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.bg-purple {
  background: #b7abea;
  border: 10px solid #f0edff;
}
.bg-green {
  background: #85dbd5;
  border: 10px solid #e2fffd;
}
.bg-pink {
  background: #e18e92;
  border: 10px solid #ffedee;
}
.value-in {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.number1 {
  margin: 0px 7px;
}
.submit-pledge .content-box .number1 p {
  font-size: 20px;
  margin-bottom: 0;
}
.value {
  font-size: 78px;
  display: flex;
  justify-content: center;
  line-height: normal;
}
.mb-0 {
  margin-bottom: 0px !important;
}

@media (max-width: 991px) {
  .submit-box {
    padding: 15px 15px 30px 15px;
  }
  .bg-purple {
    border: 5px solid #f0edff;
  }
  .quest {
    width: 90px;
    height: 90px;
  }
  .content-box h2 {
    font-size: 22px;
    margin: 15px 0px 15px 0px;
  }
  .value {
    font-size: 70px;
  }
  .submit-pledge .content-box .number1 p {
    font-size: 17px;
  }

  .mid-bg {
    background: url(../assets/newui/banner.png) center center no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
    margin-top: 40px;
    padding: 50px 0px 50px 0px;
  }

  .mid-bg {
    padding: 50px 0;
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .main-box {
    padding: 50px 30px;
  }
  .submit-pledge ul li {
    width: 100%;
    padding: 0px 15px;
    margin-bottom: 30px;
  }
  .get-start {
    margin-top: 20px;
  }
  .request-value .content-box h2 {
    min-height: auto;
    margin-bottom: 15px;
  }
  .submit-pledge .content-box .number1 p {
    min-height: auto;
  }
  .submit-box .apply-now {
    position: static;
  }
}

@media (max-width: 540px) {
}
